import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import AlertContext from '../../context/alerts/alertContext';
import AuthContext from '../../context/auth/authContext';
import ContactUsForm from '../home/ContactUsForm';
import './Footer.css'
const Footer = (props) => {
    const { alert, showAlert } = useContext(AlertContext);
    const { alertMessage, isAuth, logInUser } = useContext(AuthContext);

    useEffect(() => {
        if (isAuth) {
            // props.history.push('/projects');
        }
        if (alertMessage) {
            showAlert(alertMessage.msg, alertMessage.category);
        }
        // eslint-disable-next-line
    }, [alertMessage, isAuth, props.history]);

    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const { email, password } = loginData;

    const updateState = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (email.trim() === '' || password.trim() === '') {
            showAlert('All fields are required', 'alert-error');
        }

        logInUser({ email, password });
    };

    return (
        <>
            <footer Class="main_footer wow fadeInUp section">
                <div class="container-fluid">
                    <div class="row">
                    <div className="col-md-9">
                            <ContactUsForm /> {/* Contact form component */}
                        </div>
                        <div class="col-md-3 footer-right">
                            <a href="#">
                            <img src="assets/images/logo-white.png" width="50%"/>
                            </a>
                            <p class="mt-5">Download the The scrapify App</p>
                            <a href="#">
                            <img src="assets/images/google-play-button.png" width="50%" />
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div className='section'>
                    <div className="footer-sections">
                        <div className="footer-column">
                        <h3>Businesses</h3>
                        <a><p>EPR</p></a>
                        <a><p>Circular Economy</p></a>
                        <a><p>Paper Shredding</p></a>
                        </div>
                        <div className="footer-column">
                        <h3>Individuals</h3>
                        <a><p>Scrap Collection</p></a>
                        <a><p>Vehicle Scrapping</p></a>
                        <a><p>Zero Waste Society</p></a>
                        </div>
                        <div className="footer-column">
                        <h3>Company</h3>
                        <a><p>About Us</p></a>
                        <a><p>Careers</p></a>
                        <a><p>Franchise</p></a>
                        </div>
                        <div className="footer-column">
                        <h3>Help</h3>
                        <a><p>CSR Services</p></a>
                        <a><p>Privacy Policy</p></a>
                        <a><p>Terms & Conditions</p></a>
                        </div>
                        <div className="footer-column contact-info">
                        <div className="logo-section">
                            <img src="/assets/images/logo.jpg" alt="Logo" className="footer-logo" />
                            <h3>The Scrappify</h3>
                        </div>
                        <div className='address'>
                            <p>+91-76972 60260</p>
                            <p>thescrappify@gmail.com</p>
                            <p>Brahmanwala, Dehradun, Uttarakhand, 248121,India</p>
                        </div>
                        <div className="social-icons">
                        <FontAwesomeIcon icon={faInstagram} size="2x" color="#E1306C" className="icon-padding"/>
                        <FontAwesomeIcon icon={faFacebook} size="2x" color="#3b5998" className="icon-padding"/>
                        <FontAwesomeIcon icon={faTwitter} size="2x" color="#1DA1F2" className="icon-padding"/>
                        <FontAwesomeIcon icon={faLinkedin} size="2x" color="#0077b5" className="icon-padding"/>
                        </div> 
                        </div> 
                    </div>
                    <div className="footer-bottom">
                        <p>All rights reserved © The Scrapify.</p>
                        <p>Made with ❤️ in India, for the World</p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
