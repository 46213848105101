import React, { useState } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import './Services.css';

const Services = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);
  };

  return (
    <>
      <Header/>
      <div className="services-container">
        <div className="headerCont">
          <h1>CSR Services</h1>
        </div>

        <section className="csr-services sectionServices">
          <img src="https://www.thekabadiwala.com/images/services/csr-services/csr-services-hero.webp" alt="CSR Header" className="header-img" />
          <p>
          The primary goal of Corporate Social Responsibility (CSR) is to harmonize economic progress, social justice, and environmental sustainability. By integrating CSR into their operations, companies can achieve economic growth that aligns with the expectations and well-being of key stakeholders—suppliers, employees, customers, and the local community—while minimizing environmental impact.

Since no single CSR approach fits all industries, the strategies to fulfill CSR objectives vary depending on the sector and specific company goals. At The Kabadiwala, we specialize in helping businesses plan, design, and execute effective CSR campaigns. Our aim is to offer innovative, out-of-the-box campaign ideas that drive meaningful change, amplifying the positive impact on both the community and the environment.

Additionally, our technology-enabled platform empowers brands to meet campaign goals by providing transparency and traceability for recovered waste and its impacts. This ensures accountability while enhancing the effectiveness of sustainability initiatives.
          </p>
        </section>

        <section className="campaign-ideas sectionServices">
          <h2>Campaign Ideas</h2>
          <ul>
            <li><strong>Plastic Take Back:</strong> Help brands eliminate post-consumer plastic waste.</li>
            <li><strong>Awareness Drives:</strong> Educate the community through activities.</li>
            <li><strong>Social Inclusion of Waste Pickers:</strong> Enhance opportunities for informal workers.</li>
            <li><strong>Clean Up Drives:</strong> Help brands with local clean-up initiatives.</li>
            <li><strong>Tree Plantation:</strong> Eco-friendly brand building.</li>
          </ul>
        </section>

        <section className="prospective-clients sectionServices">
          <h2>Our Prospective Clients</h2>
          <div className="clients">
            <div>
              <img src="https://www.thekabadiwala.com/images/services/csr-services/corporate-establishments.webp" alt="Corporate" />
              <p>Corporate Establishments</p>
            </div>
            <div>
              <img src="https://www.thekabadiwala.com/images/services/csr-services/corporate-establishments.webp" alt="Private Business" />
              <p>Private Businesses</p>
            </div>
            <div>
              <img src="https://www.thekabadiwala.com/images/services/csr-services/corporate-establishments.webp" alt="Social Organizations" />
              <p>Social Organizations</p>
            </div>
          </div>
        </section>

        <section className="key-benefits sectionServices">
          <h2>Key Benefits</h2>
          <div className="benefit-item">
            <div className="benefit-icon">
              <img src="/assets/images/brand.png" alt="Brand Identity Icon" />
            </div>
            <div className="benefit-text">
              <strong>Brand Identity:</strong> <span>Improved brand identity.</span>
            </div>
          </div>
          <div className="benefit-item">
            <div className="benefit-icon">
              <img src="/assets/images/brand1.png" alt="Compliance Icon" />
            </div>
            <div className="benefit-text">
              <strong>Compliance:</strong> <span>Being compliant with various laws & regulations.</span>
            </div>
          </div>
          <div className="benefit-item">
            <div className="benefit-icon">
              <img src="/assets/images/brand2.png" alt="Innovation Icon" />
            </div>
            <div className="benefit-text">
              <strong>Innovation:</strong> <span>Encourage innovations.</span>
            </div>
          </div>
          <div className="benefit-item">
            <div className="benefit-icon">
              <img src="/assets/images/brand3.png" alt="Ethicality Icon" />
            </div>
            <div className="benefit-text">
              <strong>Ethicality:</strong> <span>Defining ethical business practices.</span>
            </div>
          </div>
        </section>


        <section className="impact-contribution sectionServices">
          <h2>Your Impacts & Contributions</h2>
          <div className="impact-grid">
            <div><strong>Environment:</strong> Reduced brand impact on nature.</div>
            <div><strong>Informal Sector:</strong> Enhanced livelihoods for workers.</div>
            <div><strong>Stakeholders:</strong> Improved stakeholder engagement.</div>
            <div><strong>Communities:</strong> Support safer streets.</div>
          </div>
        </section>

      </div>
      <Footer/>
    </>
    
  );
};

export default Services;
