import React, { useReducer } from 'react';
import AuthReducer from './authReducer';
import AuthContext from './authContext';
import axiosClient from '../../config/axios';
import authToken from '../../config/token';
import {
    SUCCESSFUL_SIGNUP,
    SIGNUP_FAILED,
    GET_USER,
    SUCCESSFUL_LOGIN,
    LOGIN_FAILED,
    LOG_OUT,
    OTP_SENT,
    SEND_OTP,
    VERIFY_OTP,
    NEW_USER
} from '../../types';

const AuthState = (props) => {
    const initialState = {
        token: localStorage.getItem('token'),
        isAuth: null,
        user: null,
        alertMessage: null,
        loading: true,
        otpSent:false,
        newUser:false,
        loginFailed:false
    };
    const [state, dispatch] = useReducer(AuthReducer, initialState);
    const sendVerificationOtp = async (data) => {
        try {
            console.log("sendotp", data)
            const response = await axiosClient.post('/users', data);
            console.log(response.data.payload)
            if(response.data.status==false){
                dispatch({
                    type: NEW_USER,
                    payload: response.data,
                });
            }else{
                dispatch({
                    type: SEND_OTP,
                    payload: response.data,
                });               
            }
           // getUserData();
        } catch (error) {
            //console.log('error.response.data.msg');
            const alert = {
                msg: error.response.data.msg,
                category: 'alert-error',
            };
        }
    }
    const sendEmailContext = async (data) => {
        try {
            console.log("sendotp", data)
            const response = await axiosClient.post('/users', data);
            console.log(response.data.payload)
            if(response.data.status==false){
                dispatch({
                    type: NEW_USER,
                    payload: response.data,
                });
            }else{
                dispatch({
                    type: SEND_OTP,
                    payload: response.data,
                });               
            }
           // getUserData();
        } catch (error) {
            //console.log('error.response.data.msg');
            const alert = {
                msg: error.response.data.msg,
                category: 'alert-error',
            };
        }
    }
    const otpVerification = async(data)=>{
        try {
            console.log("verify OTP", data)
            const response = await axiosClient.post('/users', data);
            dispatch({
                type: VERIFY_OTP,
                payload: response.data,
            });
        } catch (error) {
            console.log('error.response.data.msg', error);
            const alert = {
                msg: error.response.data.msg,
                category: 'alert-error',
            };

            console.log(error.response.data.msg);
            dispatch({
                type: LOGIN_FAILED,
                payload: alert,
            });
        }
    }

   
    const logOut = () => {
        dispatch({
            type: LOG_OUT,
        });
    };
    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                isAuth: state.isAuth,
                user: state.user,
                alertMessage: state.alertMessage,
                loading: state.loading,
                otpSent:state.otpSent,
                newUser:state.newUser,
                loginFailed:state.loginFailed,
                logOut,
                sendVerificationOtp,
                sendEmailContext,
                otpVerification
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};
export default AuthState;
