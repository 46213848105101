import React from 'react';
import BlogPost from './BlogPost';
import Header from '../common/Header';
import Footer from '../common/Footer';

function BlogList() {
  const posts = [
    {
      title: 'The Benefits of Recycling',
      date: 'October 15, 2023',
      content: 'Recycling offers significant environmental benefits by conserving natural resources, reducing waste in landfills, and lowering greenhouse gas emissions...',
    },
    {
      title: 'Top 10 Ways to Reduce Waste at Home',
      date: 'November 3, 2023',
      content: 'Start by minimizing single-use plastics, composting organic waste...',
      tips: [
        {
          title: "Practice Recycling Correctly",
          description: "Recycling is one of the easiest ways to reduce waste, but it's important to know what can and cannot be recycled. Sort paper, plastics, metals, and glass properly."
        },
        {
          title: "Compost Organic Waste",
          description: "Instead of throwing away food scraps and yard waste, composting is a great way to divert organic waste from landfills..."
        },
        {
          title: "Buy in Bulk",
          description: "Purchasing items in bulk reduces packaging waste, which is often single-use plastic..."
        },
        // Add more tips as needed
      ]
    },
    // Add more posts here
  ];

  return (
    <>
      <Header />
      <div className="blog-list">
        {posts.map((post, index) => (
          <BlogPost
            key={index}
            image="assets/images/banner-img.jpg"
            title={post.title}
            date={post.date}
            content={post.content}
            tips={post.tips} // Passing tips here
          />
        ))}
      </div>
      <Footer />
    </>
  );
}

export default BlogList;
