import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import axios from 'axios';
import Header from '../common/Header'
import Footer from '../common/Footer'
const ScrapList = (props) => {
    var baseURL = 'https://api.thescrapify.com/api/v1';
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
    const [scrapList,setScrapList] = useState([]);
    useEffect(() => {
            getScrapRates()
        // eslint-disable-next-line
    }, []);

    const getScrapRates = async () => {
       
        try {
            const response = await axios.get(baseURL+'/scrapRates',{
                headers: headers
              });
              setScrapList(response.data.payload)
              console.log(response.data.payload)
        } catch (error) {
            const alert = {
                msg: 'Something is wrong...',
                category: 'alert-error',
            };
           
        }

    }
   
    return (
        <>
        <Header/>
            
            <section class="section">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-md-12">
                        <h2 class="site-title_rates">Normal Recyclables</h2>
                    </div>
                    </div>
                    <div class="row">
                        {scrapList.map((item, index) => (
                            
                            item.category==1 && <div class="col-md-2">
                                <div class="testimonial-wrap ratelisthead">
                                    <h3>Rs. {item.scrap_price}/{item.scrap_type}</h3>
                                    <div class="content-testi rateslist">
                                        <div class="block_box local_rate_block_box web_local_rate_block_box">
                                            <img src={'https://ikp.edgekit.net/h1rxzpffx/swapeco/img/scrap-item/'+item.scrap_icon} alt="icon" class="rate_image"/>
                                        </div>
                                        {item.scrap_name}
                                    </div>
                                </div>
                            </div>
                            
                            
                        ))}
                        
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-md-12">
                        <h2 class="site-title_rates">Large Appliances</h2>
                    </div>
                    </div>
                    <div class="row">
                        {scrapList.map((item, index) => (
                            item.category==2 && <div class="col-md-2">
                                <div class="testimonial-wrap ratelisthead">
                                    <h3>{item.scrap_price}/{item.scrap_type}</h3>
                                    <div class="content-testi rateslist">
                                        <div class="block_box local_rate_block_box web_local_rate_block_box">
                                            <img src={'https://ikp.edgekit.net/h1rxzpffx/swapeco/img/scrap-item/'+item.scrap_icon} alt="icon" class="rate_image"/>
                                        </div>
                                        {item.scrap_name}
                                    </div>
                                </div>
                            </div> 
                        ))}
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-md-12">
                        <h2 class="site-title_rates">Small Appliances</h2>
                    </div>
                    </div>
                    <div class="row">
                        {scrapList.map((item, index) => (
                            item.category==3 && <div class="col-md-2">
                                <div class="testimonial-wrap ratelisthead">
                                    <h3>{item.scrap_price}/{item.scrap_type}</h3>
                                    <div class="content-testi rateslist">
                                        <div class="block_box local_rate_block_box web_local_rate_block_box">
                                            <img src={'https://ikp.edgekit.net/h1rxzpffx/swapeco/img/scrap-item/'+item.scrap_icon} alt="icon" class="rate_image"/>
                                        </div>
                                        {item.scrap_name}
                                    </div>
                                </div>
                            </div> 
                        ))}
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-md-12">
                        <h2 class="site-title_rates">Mobiles & Computers</h2>
                    </div>
                    </div>
                    <div class="row">
                        {scrapList.map((item, index) => (
                            item.category==4 && <div class="col-md-2">
                                <div class="testimonial-wrap ratelisthead">
                                    <h3>{item.scrap_price}/{item.scrap_type}</h3>
                                    <div class="content-testi rateslist">
                                        <div class="block_box local_rate_block_box web_local_rate_block_box">
                                            <img src={'https://ikp.edgekit.net/h1rxzpffx/swapeco/img/scrap-item/'+item.scrap_icon} alt="icon" class="rate_image"/>
                                        </div>
                                        {item.scrap_name}
                                    </div>
                                </div>
                            </div> 
                        ))}
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-md-12">
                        <h2 class="site-title_rates">others</h2>
                    </div>
                    </div>
                    <div class="row">
                        {scrapList.map((item, index) => (
                            item.category==5 && <div class="col-md-2">
                                <div class="testimonial-wrap ratelisthead">
                                    <h3>{item.scrap_price}/{item.scrap_type}</h3>
                                    <div class="content-testi rateslist">
                                        <div class="block_box local_rate_block_box web_local_rate_block_box">
                                            <img src={'https://ikp.edgekit.net/h1rxzpffx/swapeco/img/scrap-item/'+item.scrap_icon} alt="icon" class="rate_image"/>
                                        </div>
                                        {item.scrap_name}
                                    </div>
                                </div>
                            </div> 
                        ))}
                    </div>
                </div>
            </section>
            
        <Footer/>
        </>
    );
};

export default ScrapList;
