import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
import AlertContext from '../../context/alerts/alertContext';
import AuthContext from '../../context/auth/authContext';
import schedulePickupsContext from '../../context/schedulePickups/schedulePickupsContext';
import axiosClient from '../../config/axios';
import Header from '../common/Header'
import Footer from '../common/Footer'
const OrderSchedule = (props) => {
    var baseURL = 'https://api.thescrapify.com/api/v1';
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }

    const { alert, showAlert } = useContext(AlertContext);
    const { alertMessage, isAuth} = useContext(AuthContext);
    const { submitOrderDetails, pickupSubmitted, addressList} = useContext(schedulePickupsContext);
    // const { alertMessage, isAuth, logInUser, otpSent, sendVerificationOtp } = useContext(AuthContext);
    // const [otpSent, setOtpSent]=useState(false)
    const [myAddressList, setMyAddressList]=useState([])
    const [date, setDate]=useState("")
    const [time, setTime]=useState("")
    const [address, setAddress]=useState("")
    const [weight, setWeight]=useState("")
    const [remarks, setRemarks]=useState("")
    useEffect(() => {
       
        if (alertMessage) {
            showAlert(alertMessage.msg, alertMessage.category);
        }
        if(pickupSubmitted){
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Your pickup has been placed.",
                showConfirmButton: false,
                timer: 1500
            });
            cancelCourse()
        }
        getAddressList();
        // eslint-disable-next-line
    }, [alertMessage, isAuth, props.history, pickupSubmitted]);
    const cancelCourse = () => { 
        setDate("");
        setTime("");
        setAddress("");
        setWeight("");
        setRemarks("");
    }
    const getAddressList = async() => {
        try {
            
            const response = await axiosClient.get('address',{
                headers: headers
            });
            setMyAddressList(response.data.payload)
            console.log(response.data.payload)
        } catch (error) {
            const alert = {
                msg: 'Something is wrong...',
                category: 'alert-error',
            };
           
        }
    }
    const addNewAddress = async ()=>{
        const { value: formValues } = await Swal.fire({
            title: "Add Address",
            html: `
              <input id="swal-input1" class="swal2-input" required placeholder="Enter full address">
              <input id="swal-input2" class="swal2-input" required placeholder="Enter landmark">
              <input id="swal-input3" class="swal2-input" required placeholder="Enter Pincode">
              <select class="swal2-input swalselect" required id="swal-input4">
                <option value="">Select Address Type</option>
                <option value="Home">Home</option>
                <option value="Work">Work</option>
              </select>
            `,
            focusConfirm: false,
            preConfirm: () => {
              return [
                document.getElementById("swal-input1").value,
                document.getElementById("swal-input2").value,
                document.getElementById("swal-input3").value,
                document.getElementById("swal-input4").value
              ];
            }
        });
        if (formValues) {
            var address= {
                fulladdress:formValues[0],
                landmark:formValues[1],
                pincode:formValues[2],
                addresstype:formValues[3],
            }
            console.log(address)
            try {
            
                const response = await axiosClient.post('address',address,{headers});
                
                console.log(response.data.payload)
            } catch (error) {
                const alert = {
                    msg: 'Something is wrong...',
                    category: 'alert-error',
                };
               
            }
        }
    }
    const submitPickupDetails = async ()=>{
        console.log('safa')
        var pickupdetails={ 
            booking_date:date,
            booking_time:time,
            address_id:address,
            estimated_weight:weight,
            remarks:remarks
        }
        var pickup = await submitOrderDetails(pickupdetails)
        console.log(pickup)
    }
    return (
        <>
        <Header/>
        <div className='div'>
            <form action="" id='pickupform'>
                <div className='row'>
                    <div className='col-md-4'>
                        <label for="fname">Enter Date</label>
                        <input className='selectInput' value={date} onChange={e => setDate(e.target.value)}  type="date" id="fname" name="firstname" placeholder=""/>

                    </div>
                    <div className='col-md-4'>
                        <label for="lname">Enter Time</label>
                        <input className='selectInput' value={time} onChange={e => setTime(e.target.value)}  type="time" id="lname" name="lastname" placeholder=""/>
                    </div>
                </div>
                
                <div className='row'>
                    <div className='col-md-7'>
                        <label for="country">Select Address</label>
                        <select id="country" className='selectInput' value={address} onChange={e => setAddress(e.target.value)} >
                        <option value="">Select</option>
                        {myAddressList.map((address, index) => (
                            <option value={address.id}>{address.full_address}, {address.landmark}</option>
                        ))}
                        </select>
                    </div>
                    <div className='col-md-1'> 
                        <label for="country">.</label>
                        <input className='selectInput' onClick={addNewAddress} type="button" id="lname" value = "Add new" name="lastname" placeholder=""/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8'>
                        <label for="country">Estimated Weight</label>
                        <select id="country" className='selectInput' value={weight} onChange={e => setWeight(e.target.value)}>
                            <option value="Less than 20kg">Less than 20kg</option>
                            <option value="20-50kg">20-50kg</option>
                            <option value="100-700kg">100-700kg</option>
                            <option value="More than 700kg">More than 700kg</option>
                        </select>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-8'>
                        <label for="country">Remarks (Optional)</label>
                        <textarea className='selectInput' value={remarks} onChange={e => setRemarks(e.target.value)}></textarea>
                    </div>
                </div>

                <input type="button" onClick={submitPickupDetails} className ='btn btn-success submitbtn' value="Submit"/>
            </form>
            </div>
        <Footer/>
        </>
    );
};

export default OrderSchedule;
