import React from 'react';
import './BlogPost.css';

function BlogPost({ image, title, date, content, tips }) {
  return (
    <div className="blog-post">
      <div className="image-container">
        <img src={image} alt={title} />
      </div>
      <div className="content-container">
        <h2>{title}</h2>
        <p className="date">{date}</p>
        <div className="content">{content}</div>

        {/* Check if tips exist */}
        {tips && tips.length > 0 && (
          <div className="tips-container">
            <h3>Top Tips to Reduce Waste:</h3>
            <ul>
              {tips.map((tip, index) => (
                <li key={index}>
                  <strong>{tip.title}</strong>
                  <p>{tip.description}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default BlogPost;
