import {
    FORM_PROJECT,
    GET_PROJECTS,
    ADD_PROJECT,
    FORM_VALIDATION,
    CURRENT_PROJECT,
    DELETE_PROJECT,
    PROJECT_ERROR,
    GET_MY_PICKUPS
} from '../../types';

export default (state, action) => {
    switch (action.type) {
        case FORM_PROJECT:
            return {
                ...state,
                form: true,
            };
        case GET_PROJECTS:
            return {
                ...state,
                projects: action.payload,
            };
        case ADD_PROJECT:
            console.log('hello dear')
            return {
                ...state,
                form: false,
                formerror: false,
                pickupSubmitted:true
            };
        case GET_MY_PICKUPS:
            //console.log("osdfjf ", action.payload)
            return {
                ...state,
                form: false,
                formerror: false,
                myScheduledOrders:action.payload,
                pickupUpdated:true
            };
        case FORM_VALIDATION:
            return {
                ...state,
                formerror: true,
            };
        case CURRENT_PROJECT:
            return {
                ...state,
                currentproject: state.projects.filter(
                    (project) => project._id === action.payload
                )[0],
            };
        case DELETE_PROJECT:
            return {
                ...state,
                projects: state.projects.filter((project) => project._id !== action.payload),
                currentproject: null,
            };
        case PROJECT_ERROR:
            return {
                ...state,
                errormessage: action.payload,
            };
        default:
            return state;
    }
};
