import {
    SUCCESSFUL_SIGNUP,
    SIGNUP_FAILED,
    GET_USER,
    SUCCESSFUL_LOGIN,
    LOGIN_FAILED,
    LOG_OUT,
    OTP_SENT,
    SEND_OTP,
    VERIFY_OTP,
    NEW_USER
} from '../../types';

export default (state, action) => {
    switch (action.type) {
        case SUCCESSFUL_LOGIN:
        case SUCCESSFUL_SIGNUP:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                isAuth: true,
                loading: false,
                alertMessage: null,
            };
        case OTP_SENT:
            return {
                ...state,
                isAuth: true,
                loading: false,
                user: action.payload,
            };
        case SEND_OTP:
            return {
                ...state,
                //isAuth: true,
                loading: false,
                user: action.payload,
                otpSent:true
            };
            case NEW_USER:
                return {
                    ...state,
                    //isAuth: true,
                    loading: false,
                    user: action.payload,
                    otpSent:false,
                    newUser:true
                };
        case VERIFY_OTP:
            localStorage.setItem('token', action.payload.payload.token);
            return {
                ...state,
                isAuth: true,
                loading: false,
                user: action.payload
            }
        case GET_USER:
            return {
                ...state,
                isAuth: true,
                loading: false,
                user: action.payload,
            };    
        case LOG_OUT:
        case LOGIN_FAILED:
            return {
                ...state,
                loginFailed: true,
                user: action.payload,
            };
        case SIGNUP_FAILED:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                user: null,
                isAuth: false,
                loading: false,
                alertMessage: action.payload,
            };
        default:
            return state;
    }
};
