import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2'
import axios from 'axios';
// import withReactContent from 'sweetalert2-react-content'
import DataTable, { createTheme } from 'react-data-table-component';
import AlertContext from '../../context/alerts/alertContext';
import AuthContext from '../../context/auth/authContext';
import schedulePickupsContext from '../../context/schedulePickups/schedulePickupsContext';
import Header from '../common/Header'
import Footer from '../common/Footer'
const MyPickups = (props) => {
    var baseURL = 'https://api.thescrapify.com/api/v1';
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }


    const { alert, showAlert } = useContext(AlertContext);

    const { alertMessage, isAuth,otpSent, logInUser, sendVerificationOtp, otpVerification , user} = useContext(AuthContext);
    const { myScheduledOrders, getOrderDetails, pickupUpdated} = useContext(schedulePickupsContext);
  
    const [selectedRows, setSelectedRows] = useState([]);
    const [pending, setPending] = React.useState(true);
    const [tableDataItems, tableDataItemsRows] = React.useState([]);
    const [checkData, setCheckData] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState("Scheduled");
	useEffect(async () => {
        if(!checkData){
            getPikcupsList()
            if(pickupUpdated){
                console.log("bhai yaha h")
            }
        }
	}, [selectedRows]);

	const handleButtonClick = async (id) => {
		try {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, cancel it!"
              }).then(async (result) => {
                if (result.isConfirmed) {
                    console.log(headers)
                    axios.get(baseURL+'/bookings/cancel/'+id,{
                        headers
                    }).then((result) =>{

                        getPikcupsList()
                        Swal.fire({
                        title: "Deleted!",
                        text: "Your Pickup has been cancelled.",
                        icon: "success"
                      });

                    });
                }
              });


            
        } catch (error) {
            const alert = {
                msg: 'Something is wrong...',
                category: 'alert-error',
            };
           
        }
	};

    const getPikcupsList = async () => {
        setSelectedTab("Scheduled")
        try {
            const response = await axios.get(baseURL+'/bookings/filter/Recieved',{
                headers: headers
              });
              tableDataItemsRows(response.data.payload);
              setPending(false);
              console.log(response.data.payload)
        } catch (error) {
            const alert = {
                msg: 'Something is wrong...',
                category: 'alert-error',
            };
           
        }

    }
    const getCancelledPickupsList = async() => {
        setSelectedTab("Cancelled")
        try {
            const response = await axios.get(baseURL+'/bookings/filter/Cancelled',{
                headers: headers
              });
              tableDataItemsRows(response.data.payload);
              setPending(false);
              console.log(response.data.payload)
        } catch (error) {
            const alert = {
                msg: 'Something is wrong...',
                category: 'alert-error',
            };
           
        }
    }

    

    const getCompletedPickupsList = async() => {
        setSelectedTab("Completed")
        try {
            const response = await axios.get(baseURL+'/bookings/filter/Completed',{
                headers: headers
              });
              tableDataItemsRows(response.data.payload);
              setPending(false);
              console.log(response.data.payload)
        } catch (error) {
            const alert = {
                msg: 'Something is wrong...',
                category: 'alert-error',
            };
           
        }
    }
    
	const handleChange = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

    createTheme('solarized', {
        text: {
          primary: '#268bd2',
          secondary: '#2aa198',
        },
        background: {
          default: '#002b36',
        },
        context: {
          background: '#cb4b16',
          text: '#FFFFFF',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');
    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
                fontSize:'18px',
                color:'#110f0fb3'
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                fontSize:'20px',
                color:'##ebf2ec96',
                'font-weight': 'bold'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };


	const columns = useMemo(
		() => [
			
			{
				name: 'Booking Date',
				selector: row => row.booking_date,
				sortable: true,
				grow: 2,
			},
			{
				name: 'Booking Time',
				selector: row => row.booking_time,
				sortable: true,
			},
			{
				name: 'Estimated Weight',
				selector: row => row.estimated_weight,
				sortable: true,
				right: true,
			},
            {
				name: 'Address',
				selector: row => row.calories,
				sortable: true,
				right: true,
			},
            {
				name: 'Remarks',
				selector: row => row.remarks,
				sortable: true,
				right: true,
			},
            {
				name: 'CANCEL',
				cell: (row) => selectedTab =="Scheduled"? <button className='cancel-button' onClick={() => handleButtonClick(row.id)} >Cancel</button>:"",
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			}
		],
		[],
	);
    return (
        <>
        <Header/>
            <div className='div'>
                <section id="tabs" class="project-tab">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <nav>
                                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" onClick={getPikcupsList} aria-selected="true">SCHEDULED</a>
                                        <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" onClick={getCancelledPickupsList} aria-selected="false">CANCELLED</a>
                                        <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" onClick={getCompletedPickupsList} aria-selected="false">COMPLETED</a>
                                    </div>
                                </nav>
                                <div class="tab-content" id="nav-tabContent">
                                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <DataTable
                                        title="Scheduled Pickup List"
                                        data={tableDataItems}
                                        columns={columns}
                                        onSelectedRowsChange={handleChange}
                                        pagination
                                        responsive
                                        progressPending={pending}
                                        customStyles={customStyles}  
                                    />
                                    </div>
                                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <DataTable
                                        title="Cancelled Pickup List"
                                        data={tableDataItems}
                                        columns={columns}
                                        onSelectedRowsChange={handleChange}
                                        pagination
                                        responsive
                                        progressPending={pending}
                                        customStyles={customStyles}  
                                    />
                                    </div>
                                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <DataTable
                                        title="Completed Pickup List"
                                        data={tableDataItems}
                                        columns={columns}
                                        onSelectedRowsChange={handleChange}
                                        pagination
                                        responsive
                                        progressPending={pending}
                                        customStyles={customStyles}  
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        <Footer/>
        </>
    );
};

export default MyPickups;
