import React, { useReducer } from 'react';
import schedulePickupsContext from './schedulePickupsContext';
import schedulePickupsReducer from './schedulePickupsReducer';
import {
    FORM_PROJECT,
    GET_PROJECTS,
    ADD_PROJECT,
    FORM_VALIDATION,
    CURRENT_PROJECT,
    GET_MY_PICKUPS,
    PROJECT_ERROR,
} from '../../types';
import axiosClient from '../../config/axios';

const SchedulePickupsState = (props) => {
    const initialState = {
        projects: [],
        form: false,
        formerror: false,
        currentproject: null,
        errormessage: null,
        pickupSubmitted: null,
        myScheduledOrders:[],
        pickupUpdated:null,
        addressList:[]
    };

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }

    const [state, dispatch] = useReducer(schedulePickupsReducer, initialState);

    const showForm = () => {
        dispatch({
            type: FORM_PROJECT,
        });
    };

    const getProjects = async () => {
        try {
            const response = await axiosClient.get('/api/projects');
            dispatch({
                type: GET_PROJECTS,
                payload: response.data.projects,
            });
        } catch (error) {
            const alert = {
                msg: 'Something is wrong...',
                category: 'alert-error',
            };
            dispatch({
                type: PROJECT_ERROR,
                payload: alert,
            });
        }
    };
    const getOrderDetails = async(status) =>{
        try {
            const response = await axiosClient.get('bookings/filter/'+status,{
                headers: headers
              });
            dispatch({
                type: GET_MY_PICKUPS,
                payload: response.data.payload,
            });
        } catch (error) {
            const alert = {
                msg: 'Something is wrong...',
                category: 'alert-error',
            };
            dispatch({
                type: PROJECT_ERROR,
                payload: alert,
            });
        }
    }
    const submitOrderDetails = async (project) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
              }
            const response = await axiosClient.post('/bookings', project,{
                headers: headers
              });
            console.log(response)
            if(response.status==201){
                dispatch({
                    type: ADD_PROJECT,
                    payload: response.data,
                });
            }
        } catch (error) {
            const alert = {
                msg: 'Something is wrong...',
                category: 'alert-error',
            };
            dispatch({
                type: PROJECT_ERROR,
                payload: alert,
            });
        }
    };

    

    const selectCurrentProject = (projectId) => {
        dispatch({
            type: CURRENT_PROJECT,
            payload: projectId,
        });
    };

    const showError = () => {
        dispatch({
            type: FORM_VALIDATION,
        });
    };

   
    return (
        <schedulePickupsContext.Provider
            value={{
                myScheduledOrders:state.myScheduledOrders,
                projects: state.projects,
                pickupUpdated:state.pickupUpdated,
                form: state.form,
                formerror: state.formerror,
                currentproject: state.currentproject,
                errormessage: state.errormessage,
                pickupSubmitted:state.pickupSubmitted,
                addressList:state.addressList,
                showForm,
                getProjects,
                submitOrderDetails,
                showError,
                selectCurrentProject,
                getOrderDetails,
            }}
        >
            {props.children}
        </schedulePickupsContext.Provider>
    );
};

export default SchedulePickupsState;
