import React, { useState, useEffect } from 'react';
import Login from './components/auth/Login';
import Home from './components/home/Home';
import ScrapList from './components/home/ScrapList';
import Register from './components/auth/Register';
import Projects from './components/projects/Projects';
import OrderSchedule from './components/orderSchedule/OrderSchedule';
import MyPickups from './components/myPickups/MyPickups';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import ProjectState from './context/projects/ProjectState';
import TaskState from './context/tasks/taskState';
import AlertState from './context/alerts/alertState';
import AuthState from './context/auth/authState';
import SchedulePickupsState from './context/schedulePickups/SchedulePickupsState';
import authToken from './config/token';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import BlogList from './components/blogs/BlogList';
import Services from './components/services/Services';
import Loader from './loader/Loader'; // Loader Component

const token = localStorage.getItem('token');
if (token) {
    authToken(token);
}

function App() {
    return (
        <SchedulePickupsState>
            <ProjectState>
                <TaskState>
                    <AlertState>
                        <AuthState>
                            <Router>
                                <AppWithLoader />
                            </Router>
                        </AuthState>
                    </AlertState>
                </TaskState>
            </ProjectState>
        </SchedulePickupsState>
    );
}

function AppWithLoader() {
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => setLoading(false), 1000); // 1-second loader for demo
        return () => clearTimeout(timer);
    }, [location]);

    return (
        <>
            {loading && <Loader />}
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/register" component={Register} />
                <Route path="/logout" component={Register} />
                <Route path="/scrap-rates" component={ScrapList} />
                <Route path="/blogs" component={BlogList} />
                <Route path="/csr-services" component={Services} />
                <Route path="/admin-login" component={Login} />
                <PrivateRoute path="/projects" component={Projects} />
                <PrivateRoute path="/my/pickups" component={MyPickups} />
                <PrivateRoute path="/schedule/new" component={OrderSchedule} />
            </Switch>
        </>
    );
}

export default App;
