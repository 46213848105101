import React from 'react';
import './Loader.css'; // Include CSS for styling

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="logo">
        <img src="/assets/images/logo.jpg" alt="Logo" />
      </div>
      <div className="loading-bar">
        <div className="line"></div>
      </div>
    </div>
  );
};

export default Loader;
