// ContactUsForm.js
import React, { useState } from 'react';
import './ContactUsForm.css';

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    console.log("Form submitted:", formData);
  };

  return (
    <div className="contact-container">
      <div className="contact-info">
        <h2>Contact Information</h2>
        <p>Fill up the form and our team will get back to you within 24 hours</p>
        <div className="info-details">
          <p><span role="img" aria-label="phone">📞</span> +91-7895129395</p>
          <p><span role="img" aria-label="email">✉️</span> thescrappify@gmail.com</p>
          <p><span role="img" aria-label="location">📍</span>72VF+Q8X, Vidya Vihar Phase 2, Brahmanwala, Dehradun,Uttarakhand 248121,India</p>
        </div>
      </div>
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
            />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone Number"
            />
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
            />
          </div>
          <div className="form-group">
            <label>Subject</label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Subject"
            />
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
            ></textarea>
          </div>
          <button type="submit">Send Message</button>
          {isSubmitted && <p>Thank you for your message!</p>}
        </form>
      </div>
    </div>
  );
};

export default ContactUsForm;
